<template>
  <div class="page singer-page">
    <HeadBar
      class="singer-headbar"
      title="歌手"
      :isShowSinging="false"
      :isShowSetting="false"
      :isShowRight="true"
      :isSearch="false"
      :isShowSearchImg="true"
    />
    <div class="singer-content">
      <div class="singer-tab">
        <div
          class="singer-tab-item"
          v-for="(item, index) in tabList"
          :key="item"
          @click="handleChangeTab(item)"
        >
          <span v-if="index != 0"></span>
          <div
            class="singer-tab-item-txt"
            :class="{ 'singer-tab-item-active': curTab == item }"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <LoadMore
        class="singer-list"
        ref="loadMoreRef"
        v-if="singerUnionList.length"
        @load-more="fetchSingerList"
        safeAreaHeight="14.6991vw"
      >
        <SingerItem
          v-for="(item, index) in singerUnionList"
          :singer="item"
          :key="index"
          @click="handleClickSinger(item)"
        ></SingerItem>
      </LoadMore>
      <p class="no-data" v-if="singerUnionList.length <= 0">
        抱歉，暂无“{{ curTab }}”的结果
      </p>
      <p class="hint" v-if="isEmpty && singerUnionList.length > 20">
        已加载全部
      </p>
    </div>
  </div>
</template>

<script>
import { useShareBrowserSize } from '@/composables/sharedComposable'
// import useRecommend from '@/composables/useRecommend'
import { computed, onActivated, onUnmounted, ref, watch, nextTick } from 'vue'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { getSingerClassList, getSingerList } from '@/service/singer'
import SingerItem from '@/components/singer-item/index.vue'
import { sendLog } from '@/directives/v-log/log'
import logParams from '@/log'
import {
  // getCloseSingerRecommendTag,
  getReduceSingerRecommendSetting,
  // setCloseSingerRecommendTag,
} from '@/utils/historyCache'

export default {
  name: 'SingerList',
  components: {
    SingerItem,
  },
  activated() {
    const store = useStore()
    const { isUsed, position } = store.state.pageCacheData.singer
    if (!isUsed) {
      this.handleInitData()
    } else {
      if (this.$refs.loadMoreRef) {
        this.$refs.loadMoreRef.root.scrollTop = position
      }
      this.handleUpdateCachePosition(false, 0)
    }
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const { browserType } = useShareBrowserSize()
    // const { showRecommendConfirm } = useRecommend()

    const isShowRecommend = ref(false)

    const tabList = ref([])
    let loadMoreRef = ref(null)
    let singerList = ref([])
    let curTab = ref('')
    let p = 1
    let version = {
      current: '',
      latest: '',
    }
    let isEmpty = ref(false)
    let isRequest = false
    let timer = null

    const tabLogMap = {
      飙升周榜: 10037,
      热门歌星: 10038,
      大陆男歌星: 10039,
      中国组合: 10040,
      大陆女歌星: 10041,
      港台女歌星: 10042,
      港台男歌星: 10043,
      外国歌星: 10044,
    }

    // 歌手列表去重
    const singerUnionList = computed(() => {
      const idsMap = new Map()
      for (let i in singerList.value) {
        if (!idsMap.has(singerList.value[i].singerid)) {
          idsMap.set(singerList.value[i].singerid, singerList.value[i])
        }
      }
      return Array.from(idsMap.values())
    })

    const fetchSingerClassList = async () => {
      tabList.value = await getSingerClassList()
      handleInitTab(tabList.value[0])
    }

    const fetchSingerList = async () => {
      if (isRequest) {
        return
      }
      isRequest = true

      let bussinessResponseData = {}
      // if (tab === '飙升周榜') {
      //   bussinessResponseData = await getSingerRiseList({
      //     p,
      //     version: version.latest
      //   })
      // } else {
      bussinessResponseData = await getSingerList({
        p,
        k: curTab.value,
        version: version.latest,
      })
      // }

      if (bussinessResponseData.data.length !== 0) {
        if (p === 1 && bussinessResponseData.version) {
          version = bussinessResponseData.version
        }
        singerList.value = singerList.value.concat(bussinessResponseData.data)
        p++
      }
      isRequest = false
    }

    const handleInitTab = (val) => {
      const params = route.query
      handleChangeTab(params.tab ? params.tab : val)
    }

    const handleInitData = () => {
      singerList.value = []
      curTab.value = ''
      p = 1
      version = {
        current: '',
        latest: '',
      }
      isEmpty.value = false
      isRequest = false
      fetchSingerClassList()
    }

    const handleChangeTab = async (tab) => {
      curTab.value = tab
      if (tabLogMap[tab]) {
        sendLog({
          event_type: '10000~50000',
          event_name: tabLogMap[tab],
          event_data: {
            str1: '歌星',
            str2: '类型区',
            str3: tab,
            str4: 'click',
          },
        })
      }
      await nextTick()
      const tabElement = document.querySelector('.singer-tab-item-active');
      console.log(tabElement)
      if (tabElement) {
        tabElement.scrollIntoView({ behavior: 'smooth', inline: 'center'});
      }
    }

    const handleClickSinger = ({ singername, singerheader, singerid }) => {
      sendLog({
        event_type: '10000~50000',
        event_name: 10045,
        event_data: {
          str1: '歌星',
          str2: '歌手区',
          str3: singername,
          str4: 'click',
        },
      })
      router.push({
        name: 'songList',
        query: {
          name: singername,
          image: singerheader,
          singerid,
        },
      })
    }

    const handleUpdateCachePosition = (u, v) => {
      store.commit('UPDATE_PAGE_CACHEDATA', {
        data: {
          isUsed: u,
          position: v,
        },
        type: 'singer',
      })
    }

    /**
     * 1 - 保持推荐 60s弹出
     * 2 - 减少推荐频率 120s弹出
     * 3 - 从不推荐
     */
    const startRecommendTimer = () => {
      const setting = getReduceSingerRecommendSetting()
      if (setting === 3) return
      timer = setTimeout(
        () => {
          isShowRecommend.value = true
          sendLog(logParams.get(30308))
        },
        setting === 2 ? 120000 : 60000
      )
    }

    const clearRecommendTimer = () => {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
    }

    watch(curTab, (tab) => {
      if (tab) {
        singerList.value = []
        p = 1
        fetchSingerList()
      }
    })

    const closeRecommend = () => {
      isShowRecommend.value = false
    }

    onBeforeRouteLeave((to, from, next) => {
      clearRecommendTimer()
      closeRecommend()
      if (to.name === 'search' || to.name === 'songList') {
        const position = loadMoreRef.value
          ? loadMoreRef.value.root.scrollTop
          : 0
        handleUpdateCachePosition(true, position)
      }
      next()
    })

    onActivated(() => {
      console.log('singer onActivated')
      startRecommendTimer()
    })

    onUnmounted(() => {
      clearRecommendTimer()
      closeRecommend()
    })

    return {
      tabList,
      loadMoreRef,
      singerList,
      singerUnionList,
      curTab,
      p,
      isEmpty,
      // isShowRecommend,
      handleInitData,
      handleChangeTab,
      handleClickSinger,
      handleUpdateCachePosition,
      fetchSingerList,
      browserType,
      // handleCloseRecommend,
    }
  },
}
</script>

<style lang="stylus" scoped>
.page
  min-height 100vh
  .search-bar
    background none
  .singer-content
    position relative !important
    display flex
    flex-direction column
    align-items center
    width 100vw
    height 100%
.singer-page
  padding 150px 0px 0
  height 100vh
  overflow hidden 
  .singer-tab
    width calc(100vw - 164px * 2)
    height 100px
    display flex
    align-items center
    justify-content space-between
    overflow-x scroll
    margin-bottom 50px
    margin 12px 164px 0
    position relative
    &:after
      content ""
      width 100%
      height 2px
      background rgba(29, 29, 31, 0.1)
      position absolute
      left 0px
      bottom 2px
    @media screen and (max-width 1200px) and (min-height 1200px)
      margin 0px
      width calc(100vw - 64px * 2)
      margin 0 64px
    &::-webkit-scrollbar
      display none
    &-item
      width auto
      height 100%
      display flex
      align-items center
      flex-shrink 0
      position relative
      @media screen and (max-width 1200px) and (min-height 1200px)
        margin-right 36px
      &-txt
        color #1D1D1F80
        font-size var(--font-size-large)
        font-weight 400
        padding 0 16px
        width auto
        max-width 400px
        white-space nowrap
        overflow hidden
        text-overflow ellipsis
        height 100px
        line-height 100px
        position relative
      &-active
        color #A04AF0
        font-weight 400
        &:after
          content ""
          width 100%
          height 2px
          background #A04AF0
          position absolute
          left 0px
          bottom 2px
  .singer-list
    display grid
    text-align center
    grid-template-columns repeat(6, 216px)
    width calc(100vw - 182px * 2)
    justify-content space-between
    padding 48px 0 100px 0
    box-sizing border-box
    ::v-deep .singer-item
      width 216px
      height 275px
      padding 0px
      margin-bottom 60px
      .singer-item-cover
        width 216px
        height 216px
        margin-bottom 17px
      p
        width 216px
        line-height 1
    &::-webkit-scrollbar
      display none
    @media screen and (max-width 1200px) and (min-height 1200px)
      width 1016px
      grid-template-columns repeat(4, 200px)
      padding-bottom 200px !important
      ::v-deep .singer-item
        width 200px
        height 246px
        margin-bottom 60px
        margin-top 0px
        .singer-item-cover
          width 200px
          height 200px
          margin-bottom 20px
        p
          width 200px
          line-height 1
  .no-data
    font-size 28px
    color #1D1D1F80
    text-align center
    line-height 650px
    @media screen and (max-width: 1200px)
      height 650px
  .hint
    text-align center
    color #555555
  .singer-headbar
    background transparent
  .singer-bar-pure
    background transparent
    position fixed
    top 164px
    left 1014px
    width 826px
    padding 0
    // margin auto
    @media screen and (max-width 1200px) and (min-height 1200px)
      top 775px
      left 0
      right 0
      bottom 0
      width 100%
      padding 0 5vw
</style>
